import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import Charts from 'react-apexcharts';
import { Col } from 'react-bootstrap';

const OrderDashboard = () => {

    const { userSession } = useContext(AccountContext);
    
    const [previousMonthOrders, setPerviousMonthOrders] = useState([])
    const [thisMonthOrders, setThisMonthOrders] = useState([])
    const [commissonChart, setCommissionChart] = useState(false)

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    //Get Dates
    let now = new Date();
    let firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    let lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    let firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastDayThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // Convert to Unix timestamp
    let firstDayLastMonthUnixTimestamp = Math.floor(firstDayLastMonth.getTime() / 1000);
    let lastDayLastMonthUnixTimestamp = Math.floor(lastDayLastMonth.getTime() / 1000);
    let firstDayThisMonthUnixTimestamp = Math.floor(firstDayThisMonth.getTime() / 1000);
    let lastDayThisMonthUnixTimestamp = Math.floor(lastDayThisMonth.getTime() / 1000);

    const commissionChartCall = () =>{
        
        ApiRequestBuilder('oepSaleRepChartGet').then(function (results) {
            if (results.data && results.data.length > 0)
            {
                console.log(results);
                setCommissionChart({
                    series: [{
                        data: [results.data[0].created_commission, results.data[0].submitted_commission, results.data[0].closed_commission]
                    }],
                    plotOptions: {
                        bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                        },
                        xaxis: {
                            categories: ['Created', 'Submitted', 'Closed'],
                        },
                        chart: {
                            type: 'bar',
                            height: 350
                        },
                    },
                    dataLabels: {
                        enabled: false
                    }
                })
            }
            else ToastBuilder('info', 'Unable to Create Chart')
        })

    };

    const dashboardOrders = (dates) => {
        ApiRequestBuilder('oepOrdersDashboardPost', null, dates)
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    if (dates.previousMonth){
                        console.log('Prev');
                        setPerviousMonthOrders(results.data)
                    }

                    else{
                        console.log('Current');
                        setThisMonthOrders(results.data)
                    }

                } 
            }
        )
    }

    useEffect(() => {
        commissionChartCall()
        dashboardOrders({"searchFrom":firstDayLastMonthUnixTimestamp, "searchTo": lastDayLastMonthUnixTimestamp, "previousMonth": true})
        dashboardOrders({"searchFrom":firstDayThisMonthUnixTimestamp, "searchTo": lastDayThisMonthUnixTimestamp, "previousMonth": false})
    },[]);

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <h4>Order Dashboard</h4>
                    { commissonChart != false ? 
                        <Col>
                            <Charts options={commissonChart.plotOptions} series={commissonChart.series} type="bar" height={350} />
                        </Col>
                    : null}
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <TableBuilder
                        data = {previousMonthOrders}
                        processing = {false}
                        subHeader={true}
                         columnsToSearch = {['data']}
                        title='Previous Month Orders'
                        columns = {[
                            {
                                name: 'Category',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <TableBuilder
                        data = {thisMonthOrders}
                        processing = {false}
                        subHeader={true}
                         columnsToSearch = {['data']}
                        title='Current Month Orders'
                        columns = {[
                            {
                                name: 'Category',
                                selector: (row) => row.data,
                                sortable: true,
                                center: true
                            },
                            {
                                name: 'Total Line',
                                selector: (row) => row.total_line_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Created',
                                selector: (row) => row.created_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Submitted',
                                selector: (row) => row.submitted_orders,
                                sortable: true
                            },
                            {
                                name: 'Total Closed',
                                selector: (row) => row.closed_orders,
                                sortable: true
                            }
                        ]}
                    />
                </CardBody>
            </Card>
        </Fragment>
    )
};

export default OrderDashboard;