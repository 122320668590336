import { Modal, ModalBody, ModalFooter, ModalHeader, DropdownItem, DropdownMenu, ButtonDropdown, DropdownToggle, Button } from 'reactstrap';
import { Row, Col, CardBody, Card, CardHeader, CardFooter, FormGroup, Label, Input, Popover, PopoverBody, PopoverHeader, Container } from 'reactstrap'
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Btn, H5, H4 } from '../../AbstractElements';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import buildRandomId, { lowercaseFirstLetter } from '../Helpers';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FilterComponent from './FilterComponent';
import * as feather from 'react-feather';

const CardBuilder = ({ title, subTitle, body, footer }) => {

    footer ? footer : true

    return ( 
        <Card>
            <CardHeader>
                <H5>{title}</H5><span>{subTitle}</span>
            </CardHeader>
            <CardBody>
                {body}
            </CardBody>

            {footer ?
            <CardFooter>
                <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }} >Submit</Btn>
                <Btn attrBtn={{ color: "light", type: "submit" }} >Cancel</Btn>
            </CardFooter> : null }
        </Card>
    )
}

const FormatError = (error, label) => {

    if (error) {

        let errorMessage = label + ' '

        console.log('Formatting Error', error)

        switch (error.type)
        {
            case 'minLength':
            case 'maxLength':
                return errorMessage += 'does not meet the length requirements'
            case 'validate':
                return error.message
            default:
                return errorMessage += ' is required'
        }

    }
}

const RegisterInput = ({name, label, type, options, value, style, helpText, labelSize, inputSize, placeholder, required, popover_text, onChange, onKeyDown, registrator, registratorErrors, registrationSettings = {}, id = buildRandomId(7), visible = true}) => {

    if (!visible) return null
    
    let className = 'form-control'
    let readOnly = false

    name = name ? name : lowercaseFirstLetter(label).replaceAll(' ', '')
    
    className = type === 'select' ? className + ' btn-square' : ''
    options = type === 'select' ? options : null
    required = required ? required : false
    readOnly = type === 'static' ? true : false

    labelSize = labelSize ? labelSize : '4'
    inputSize = inputSize ? inputSize : '8' 
    
    /**
     * For some reason when unpacking the returned objects from the registrator
     * function, we have to use the variable name "ref". If not, the unpacking
     * doesn't work correctly. Thus, for each input we need to create the ref
     * dynamically as we can't define "ref" more than once.
     */
    const {ref,...registration} = registrator(name, {
        required: required,
        ...registrationSettings
    });

    return (
        <Fragment>
            
            <Col sm={labelSize} className="mb-2" style={{ textAlign: 'right'}}>
                {required ? <span style={{ color: "red" }}> * </span> : null}

                <Label className="col-form-label">{label}</Label>
            </Col>
            <Col sm={inputSize}>
                {type !== 'separator' ?  
                    (<Input 
                        key={buildRandomId(7)} 
                        id={id} 
                        className={className} 
                        type={type} 
                        style={style}
                        placeholder={placeholder} 
                        {...registration} 
                        innerRef={ref} 
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={value}
                        readOnly={readOnly}
                    >
                        {options}
                    </Input>) : <hr/>}
            </Col>
            { helpText ? <span style={{ color: "gray", fontSize: 12 }} className={'offset-md-' + labelSize}>{helpText} </span> : null }
            <span style={{ color: "red" }}>{FormatError(registratorErrors[name], label)} </span>

            {popover_text ?
                <PopoverBuilder item={{
                    id: id,
                    placement: 'right',
                    Popoverbody: popover_text,
                    btncolor: 'primary'
                }}/> : null}
        </Fragment>
    )
}

const RegisteredInputsBuilder = ({ inputs, registrator, registratorErrors }) => {

    return inputs.map(function(input){
        
        let { size } = input
        
        size = size ? size : '3'
        
        return (
            <Col md={size} key={buildRandomId(7)}>
                <FormGroup className="row" key={buildRandomId(7)}>
                        <RegisterInput {...input} registrator={registrator} registratorErrors={registratorErrors}/>
                </FormGroup>
            </Col>
        )
    })
}

/**
 * Our API restricts endpoints to logged in Cognito users. In order to validate that the
 * requestor is a registered user, the apiToken is store in the localStorage. We have to
 * send that token in the header everytime we make a request.
 */
const ApiRequestBuilder = (apiRequest, requestParams = null, requestBody = null, requestOptions = null, queryParams = null) => {

    var headers = {
        'headers': {
            authorization: localStorage.getItem('apiToken')
        }
    }
    if (!requestOptions){
        requestOptions = {};
    }

    if (queryParams != null){
        headers.queryParams = queryParams;
    }

    return window.apigClientFactory.newClient()[apiRequest](requestParams, requestBody, headers)
        .then(res => {

            requestOptions.callback ? requestOptions.callback() : null
            requestOptions.setIsProcessing ? requestOptions.setIsProcessing(false) : null
            requestOptions.toastOnSuccess ? ToastBuilder('success', requestOptions.toastOnSuccess) : null

            return res
        })
        .catch(err => {
            console.log(err)

            requestOptions.toastOnError ? ToastBuilder('error', requestOptions.toastOnError) : null
            requestOptions.setIsProcessing ? requestOptions.setIsProcessing(false) : null

            switch (err.data.message)
            {
                case 'Unauthorized':
                case 'The incoming token has expired':
                    if (!window.location.href.includes('login') && !window.location.href.includes('signup'))
                    {
                        ToastBuilder('error', 'Please Login Again')
                        window.location.href = process.env.PUBLIC_URL + '/logout'
                    }
                    break;
                default:
                    ToastBuilder('error', 'Unable to make request at this time')
                    console.log('API Error:', err.data)
                    break;
            }

            return { data: [] }

        })
}

toast.configure();

const ToastBuilder = (type, message = '', style = {}, autoclose = 1500) => {

    switch (type) {
        case 'success':
            toast.success(message, {
                style: style,
                position: toast.POSITION.TOP_CENTER, 
                autoClose: autoclose,
                className: 'black-background success',
            });
            break;
        case 'info':
            toast.info(message, {
                style: style,
                position: toast.POSITION.TOP_CENTER,
                autoClose: autoclose,
                className: 'info',
            });
        break;
        case 'warn':
            toast.warn(message, {
                style: style,
                position: toast.POSITION.TOP_CENTER,
                autoClose: autoclose,
                className: 'warning',
            });
        break;
        case 'error':
            toast.error(message, {
                style: style,
                position: toast.POSITION.TOP_CENTER,
                autoClose: autoclose,
                className: 'black-background error',
                
            });
        break;
        default:
        break;
    }
}

const CommonModal = ({ isOpen, toggler, size, title, bodyClass, style={}, children, formId = '', onSubmit = true, onSubmitLabel = 'Submit', onCancelLabel = 'Cancel', onDelete = false, onDeleteLabel = 'Delete' }) => {
    return (
      <Modal isOpen={isOpen} toggle={toggler} size={size} centered style={style}>
        <ModalHeader toggle={toggler}>
          {title}
        </ModalHeader>
        <ModalBody className={bodyClass}>
          {children}
        </ModalBody>
        <ModalFooter>
            {onDelete ? <ButtonBuilder label={onDeleteLabel} color='danger' onClick={() => { onDelete(); toggler() }} useSpinner={false} /> : null}
            <ButtonBuilder label={onCancelLabel} color='light' onClick={toggler} useSpinner={false} />
            {onSubmit ? <ButtonBuilder label={onSubmitLabel} onClick={toggler} useSpinner={false} form={formId ? formId : title.replaceAll(' ', '-').toLowerCase() + '-form'} /> : null}

        </ModalFooter>
      </Modal>
    );
  };

const ModalBuilder = ({ modalTitle, menuItem }) => {
    const [modal, setModal] = useState(false);
    const toggle = (event) => {
      setModal(!modal);
    }
    return (
      <Fragment> 
        <a href="javascript"
            id="nav-link"
            className={`nav-submenu menu-content`}
            onClick={(event) => {
              event.preventDefault(); 
              toggle();
            }} >
            {menuItem.icon !== undefined && <menuItem.icon />}
            <span>{menuItem.title}</span>
        </a>
        <CommonModal isOpen={modal} title={modalTitle} toggler={toggle} >
            {menuItem.modal}
        </CommonModal>
      </Fragment>
    );
  };

  const FormOptionsBuilder = ({options, isGroup = false}) => {

    if (isGroup)
    {
        return Object.keys(options).map(group => {
            return (
                <optgroup key={group} label={group}>
                    {options[group].map(items => (
                        <option value={items.value} key={buildRandomId(7)}>{items.label}</option>
                    ))}
                </optgroup>
            )
        })
    }

    return options.map((items, i) =>
        <option value={items.value} key={buildRandomId(7)}>{items.label}</option>
    )
  }

  const ModalLinkBuilder = ({ label = '', customLabel = null, title = '', body = null, className = null, onClick = null, size = 'sm', onSubmit = true, onSubmitLabel, onCancelLabel, onDelete = false, onDeleteLabel }) => {
    const [modal, setModal] = useState(false);
    const toggle = (event) => {
        setModal(!modal);
    }
    return (
        <Fragment>
            <a href="javascript"
                className={className}
                onClick={(event) => {
                    event.preventDefault();
                    onClick ? onClick() : null;
                    toggle();
                }} >
                {customLabel ? customLabel : <span>{label}</span>}
            </a>
            <CommonModal isOpen={modal} title={title} toggler={toggle} size={size} onSubmit={onSubmit} onSubmitLabel={onSubmitLabel} onCancelLabel={onCancelLabel} onDelete={onDelete} onDeleteLabel={onDeleteLabel} >
                {body}
            </CommonModal>
        </Fragment>
    );
};

  const ModalButtonBuilder = ({ title = '', body = null, className = null, formId = '', type = 'submit', onClick = null, size = 'large', style = {}, onSubmit = true, onSubmitLabel, onCancelLabel, onDelete = false, onDeleteLabel }) => {
    const [modal, setModal] = useState(false);
    const toggle = (event) => {
      setModal(!modal);
    }
    return (
      <Fragment> 
        <ButtonBuilder 
            label={title}
            useSpinner={false}
            formId={formId}
            type={type}
            className={className ? className : 'col-md-2'} 
            onClick={() => {
                toggle();
                onClick ? onClick() : null;
            }}
        />
        <CommonModal isOpen={modal} title={title} toggler={toggle} formId={formId} size={size} style={style} onSubmit={onSubmit} onSubmitLabel={onSubmitLabel} onCancelLabel={onCancelLabel} onDelete={onDelete} onDeleteLabel={onDeleteLabel}>
            {body}
        </CommonModal>
      </Fragment>
    );
  };

const ButtonBuilder = ({label = 'Submit', type = 'submit', color = 'primary', className, form = null, useSpinner = true, onClick = null, onClickPromise = null}) => {

    const [spinner, setSpinner] = useState(false);

    const onButtonClick = () => {
        /**
         * Some places where a button is used may not 
         * need a spinner, like launching a modal
         */
        if (useSpinner)
        {
            setSpinner(!spinner);
        }

        /**
         * If we were given a function to call, call it here
         * and then shut off the spinner. In order for the spinner 
         * to turn off at the right time, the onClickPromise function must
         * return a promise.
         */
        if (onClickPromise)
        {
            onClickPromise().then( () => setSpinner(false) )
        }
        else
        {
            if (onClick) onClick()
            setTimeout( () => setSpinner(false), 2000 )
        }
    }

    return (
        <Button className={'btn ' + className} color={color} type={type} onClick={onButtonClick} form={form}>
            {spinner ? <Spinner animation="border" size="sm" role="status"></Spinner> : label}
        </Button>
    )
}

const DropdownBuilder = ({items, label}) => {
  
    // ButtonDropdown open state
    const [dropdownOpen, setOpen] = useState(false);

    return (
        <div className='dropdown'>
            <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
                isOpen={dropdownOpen}>
                <DropdownToggle color='black' caret>
                    {label}
                </DropdownToggle>
                <DropdownMenu>
                    {items.map((item, i) => <DropdownItem key={i} onClick={item.onClick}>{item.label}</DropdownItem>)}
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    );
};

const PopoverBuilder = ({ item }) => {
    const [popover, setPopover] = useState(false);
    const Toggle = () => setPopover(!popover);
    return (
        <>
        <Popover
            placement={item.placement}
            isOpen={popover}
            target={item.id}
            toggle={Toggle}
            offset={item.offset}
            trigger={item.trigger}
    
        >
            <PopoverHeader>{item.Popoverheader}</PopoverHeader>
            <PopoverBody>
            {item.Popoverbody}
            </PopoverBody>
        </Popover>
        </>
    );
}

const customStyles = {
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '2px',
                borderRightColor: 'rgba(51,51,51,.2)',
            },
        },
    }
};

// https://react-data-table-component.netlify.app/?path=/docs/api-columns--page
const TableBuilder = ({data, title, columns, actions, columnsToSearch = [], pagination = true, sortField = 1, paginationPerPage = 10, sortOrderAsc = true, subHeader = false, processing = true,  styles = {}}) => {
    
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = filterText === '' ? data : data.filter(
        /* Converting the column value to a String allows searching for numbers */
        item => columnsToSearch.some(column => item[column] && (item[column]).toString().toLowerCase().includes(filterText.toLowerCase())),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            dense
            title={title}
            progressPending={processing}
            progressComponent={<h4 className='m-3'>Fetching Data...</h4>}
            pagination={pagination}
            paginationPerPage={paginationPerPage}
            paginationComponentOptions={{
                selectAllRowsItem: true
            }} 
            defaultSortFieldId={sortField}
            defaultSortAsc={sortOrderAsc}
            customStyles={{...customStyles, ...styles}}
            subHeader={subHeader}
            subHeaderComponent={subHeaderComponentMemo}
            data={filteredItems}
            center={true}
            striped={true}
            actions={actions}
            columns={columns}
            persistTableHead 
            highlightOnHover={true}
        />
    );
}

const TextTitleBuilder = (text, style={fontWeight:'normal'}) => {

    return <span title={text} style={style}>{text}</span>
}

const Tile = ({ tile }) => {

    const Icon = feather[tile.icon]
    const BackgroundIcon = tile.backgroundIcon ? feather[tile.backgroundIcon] : Icon
    const title = tile.link ? <Link className='tile-title-link' to={`${tile.link}`}>{tile.title}</Link> : tile.title

    var content = null

    if (tile.count){
        content = <H4 attrH4={{ className: 'mb-0 tile-counter' }} >{tile.count}</H4>
    } else if(tile.content){
        content = <H4 attrH4={{ className: 'mb-0 tile-content' }} >{tile.content}</H4>
    }

    const footerStyle = { border: '0px solid blue', position: 'absolute', left: '10px', bottom: '5px', fontSize: '10px', color: 'gray' }

    return (
        <div className="media static-top-widget">
            <div className="align-self-center text-center">
                <Icon />
            </div>
            <div className="media-body">
                <span className="m-0 tile-title">{title}</span>
                {content}
                <BackgroundIcon className="icon-bg" />
            </div>
            <div className="media-footer" style={footerStyle}>
                {tile.footNote}
            </div>
        </div>
    )
}

const TilesBuilder = ({ data }) => {
    if (!data) {
        return null;
    }
    return (
        <Row>
            {
                data.filter(element => !element.omit).map((tile) => {
                    return (

                        <Col sm="6" xl="3" lg="6" key={tile.id ? tile.id : buildRandomId(7)} >
                            <Card className="o-hidden border-0">
                                <CardBody>
                                    <Tile tile={tile} />
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })
            }
        </Row>
    )
}

const HoverOver = ({ content, text, textStyle = {}, callback=null, callbackContent=null }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const containerRef = useRef(null); // Reference to the hoverable element

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '80px',
        minHeight: '35px',
        wordWrap: 'break-word',
        textAlign: 'center',
        position: 'relative',
    };

    const bubbleStyle = {
        position: 'fixed',
        top: position.top,
        left: position.left,
        transform: 'translateX(-50%)', // Center the tooltip horizontally
        padding: '10px',
        maxWidth: '550px',
        maxHeight: '600px',
        backgroundColor: 'rgba(51, 51, 51, 0.95)',
        color: '#fff',
        borderRadius: '5px',
        whiteSpace: 'normal',
        zIndex: 99,
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        opacity: isHovered ? 1 : 0,
        pointerEvents: 'none', // set to auto/none to enable/disable clicking on the tooltip
        textAlign: 'left',
        overflow: 'auto',
    };

    // The content is text, there may be new lines, or tabs. Replace with appropriate HTML
    const formatContent = (text) => {
        // Split the text by newlines
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {/* Replace occurrences of 4 spaces or tabs with a tab equivalent (e.g., &emsp; or &nbsp;) */}
                {line.split(/( {4}|\t)/).map((part, idx) =>
                    part === '    ' || part === '\t' ? <span key={idx}>&emsp;</span> : part
                )}
                <br />
            </React.Fragment>
        ));
    };
        // dynamically set top and left positions based on parent container
    const handleMouseEnter = () => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom - 5, // Position below container
                left: rect.left + rect.width / 2, // Tooltip origin on container center
            });
            setIsHovered(true);
        }
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div style={{...containerStyle, ...textStyle}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={containerRef}>
            {/* onDoubleClick={()=>setIsHovered(false)}
            onClick= {onClick ? onClick(content) : null} */}
            {text}
            {isHovered &&
                <div style={bubbleStyle}>{formatContent(content)}</div>
            }
            {/* {callback ? callback(callbackContent) : null} */}
        </div>
    );
};

export default TableBuilder;
export { 
    CardBuilder, 
    RegisteredInputsBuilder, 
    ApiRequestBuilder, 
    ToastBuilder, 
    ButtonBuilder, 
    ModalBuilder, 
    PopoverBuilder, 
    DropdownBuilder, 
    ModalButtonBuilder, 
    FormOptionsBuilder, 
    ModalLinkBuilder,
    TextTitleBuilder,
    TilesBuilder,
    HoverOver
}